import Image from "next/image";
import React, { useCallback, useRef, useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";
import { useActiveAccount } from "thirdweb/react";
import toast from "react-hot-toast";
import StripeCheckout from "../../components/buyAIcredits/StripeCheckout";
import Loading from "../../components/Loading";
import { Fade } from "react-reveal";
import { useRouter } from 'next/router';
import { useAuthRedirect } from '../../hooks/useAuthRedirect';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
// import 'swiper/css/effect-coverflow';
import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules';

const pricingTiers = [
  {
    name: "Free",
    credits: 100,
    price: 0,
    description: "Perfect for small projects and basic use.",
    creditValue: "1000",
    isPopular: false,
    image: "/images/packages/lite.png",
    packageId: "prod_QpZcwpOXMBWIE3",
  },
  {
    name: "Basic",
    credits: 1000,
    price: 100,
    description: "Perfect for small projects and occasional use.",
    creditValue: "10,000",
    isPopular: false,
    image: "/images/packages/lite.png",
    packageId: "prod_QpZcwpOXMBWIE3",
  },
  {
    name: "Pro",
    credits: 5000,
    price: 400,
    description: "Ideal for regular use and medium-sized projects.",
    creditValue: "50,000",
    isPopular: true,
    image: "/images/packages/standard.png",
    packageId: "prod_QpZXJcHO1phEtr",
  },
  // {
  //   name: "Epic",
  //   credits: "10,000",
  //   price: 700,
  //   description: "Best for power users and large-scale projects.",
  //   creditValue: "100,000",
  //   isPopular: false,
  //   image: "/images/packages/pro.png",
  //   packageId: "prod_QpZXLdOLlZl8IF",
  // },
  {
    name: "Enterprise",
    credits: "Unlimited",
    price: "Custom",
    description: "Best for enterprises.",
    creditValue: "custom",
    isPopular: false,
    image: "/images/packages/pro.png",
    packageId: null,
  },
];
function Pricing({ PricingPage, home }) {
  const sliderRef = useRef(null);
  const walletAddress = useActiveAccount();
  const router = useRouter();
  const { redirectToSignIn } = useAuthRedirect();

  const [checkout, setCheckout] = useState(false);
  const [checkoutLoading, setCheckoutLoading] = useState(false);

  const [isAtBeginning, setIsAtBeginning] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);

  const handleSlideChange = () => {
    if (sliderRef.current) {
      const swiper = sliderRef.current.swiper;
      setIsAtBeginning(swiper.isBeginning);
      setIsAtEnd(swiper.isEnd);
    }
  };
  const [isAnnual, setIsAnnual] = useState(true);
  const calculatePrice = (price) => {
    return isAnnual ? price * 0.8 : price;
  };

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);



  const handlePurchaseClick = (tier) => {
    if (tier.packageId === null) {
      document.getElementById("contact").scrollIntoView({ behavior: "smooth" });
    } else if (walletAddress) {
      setCheckout(tier.packageId);
      setCheckoutLoading(true);
    } else {
      redirectToSignIn();
    }
  };

  return (
    <>
      <div className="">
        <section
          className={` w-full container h-full text-jacarta-900 dark:text-white ${
            PricingPage
              ? "pt-[116px] md:pt-[150px]  lg:pt-[180px]   pb-[68px] md:pb-[98px]  lg:pb-[118px] "
              : " py-12 md:py-[98px]  lg:py-[118px] "
          }`}
        >
          <div className="">
            <div className={`flex flex-col lg:flex-row justify-between items-center gap-4 lg:gap-12 ${ PricingPage ? 'pt-14 pb-5' :'py-5'} mb-10`}>
              <Fade left delay={300}>
                <div className="max-w-[650px] w-full">
                  <h1 className="text-3xl font-bold tracking-tighter sm:text-5xl xl:text-6xl/none text-jacarta-900 dark:text-white mb-3">
                    Start Free, Scale with Your Brand
                  </h1>
                  <p className="text-lg text-gray-400 dark:text-jacarta-400 ">
                    Our flexible plans fit any brand size. Start for free and
                    upgrade as your community grows
                  </p>
                </div>
              </Fade>
              <Fade top delay={300}>
                <div className="flex items-center justify-center my-auto w-full">
                  <div className="relative flex w-full p-3 rounded-lg">
                    <span
                      className="absolute inset-0 m-1 pointer-events-none dark:bg-jacarta-800 bg-white shadow-md rounded-lg"
                      aria-hidden="true"
                    >
                      <span
                        className={`absolute inset-0 w-1/2 bg-gradient-to-tr from-hotPink via-purplish to-accent  rounded-lg shadow-sm shadow-indigo-950/10 transform transition-transform duration-150 ease-in-out ${
                          isAnnual ? "translate-x-0" : "translate-x-full"
                        }`}
                      ></span>
                    </span>
                    <button
                      className={` relative flex-1 text-md font-medium h-8 rounded-lg focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 dark:focus-visible:ring-slate-600 transition-colors duration-150 ease-in-out ${
                        isAnnual
                          ? "text-white font-semibold"
                          : "dark:text-jacarta-400text-jacarta-600"
                      }`}
                      onClick={() => setIsAnnual(true)}
                      aria-pressed={isAnnual}
                    >
                      Yearly -20%
                    </button>
                    <button
                      className={` relative flex-1 text-md font-medium h-8 rounded-lg focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 dark:focus-visible:ring-slate-600 transition-colors duration-150 ease-in-out ${
                        isAnnual
                          ? " dark:text-jacarta-400text-jacarta-600"
                          : "text-white font-semibold"
                      }`}
                      onClick={() => setIsAnnual(false)}
                      aria-pressed={!isAnnual}
                    >
                      Monthly
                    </button>
                  </div>
                </div>
              </Fade>
              
            </div>

            <div className="px-10 relative">
       
                <div className="lg:hidden flex justify-center items-center gap-6 ">
                  <div
                    onClick={handlePrev}
                    className={` top-[50%] translate-y-[-50%] absolute left-0 z-[9999] rounded-full p-2
                      ${!isAtBeginning
                        ? " bg-gradient-to-tr from-hotPink via-purplish to-accent  button cursor-pointer  transition-all duration-500   disabled:bg-white text-white"
                        : "dark:bg-jacarta-800 text-white  opacity-50 cursor-not-allowed  transition-all duration-500  disabled:bg-white  "}
                    `}
                    disabled={isAtBeginning}
                  >
                    <MdArrowForwardIos
                      className={
                        ` rotate-180
                       ${!isAtBeginning
                          ? "w-[20px] h-[20px] text-white"
                          : "w-[20px] h-[20px] dark:text-white text-jacarta-800"
                       }`}
                    />
                  </div>
                  <div
                    onClick={handleNext}
                    className={` top-[50%] translate-y-[-50%] absolute right-0 z-[9999]  rounded-full p-2
                      ${!isAtEnd
                        ? "bg-gradient-to-tr from-hotPink via-purplish to-accent  button cursor-pointer  transition-all duration-500   disabled:bg-white text-white"
                        : "dark:bg-jacarta-800 text-white opacity-50 cursor-not-allowed  transition-all duration-500  disabled:bg-white  "}
                    `}
                    disabled={isAtEnd}
                  >
                    <MdArrowForwardIos
                      className={
                        !isAtEnd
                          ? "w-[20px] h-[20px] text-white"
                          : "w-[20px] h-[20px] dark:text-white text-jacarta-800"
                      }
                    />
                  </div>
                </div>
              
              <Fade left delay={300}>
                <Swiper
                   effect={'coverflow'}
                   grabCursor={true}
                  //  centeredSlides={true}
                   slidesPerView={'auto'}
                  //  initialSlide={1}
                  //  coverflowEffect={{
                  //    rotate: 50,
                  //    stretch: 0,
                  //    depth: 100,
                  //    modifier: 1,
                  //    slideShadows: true,
                  //  }}
                   pagination={true}
                   modules={[Navigation, Pagination]}
                  //  className="!pb-14 !pt-4"
                  ref={sliderRef}
                  onSlideChange={handleSlideChange}
                  spaceBetween={30}
                  breakpoints={{
                    240: {
                      slidesPerView: 1,
                    },
                    565: {
                      slidesPerView: 2,
                    },
                    1026: {
                      slidesPerView: 4,
                    },
                  }}
                  className="!border-box card-slider-4-columns !py-5 relative"
                >
                  {pricingTiers.map((tier) => {
                    const { id } = tier;
                    return (
                      <SwiperSlide key={id}>
                        <div className={`${tier.isPopular ? "gradient-box" : 'border border-gray-300 dark:border-gray-200 rounded-lg '}`}>
                          <div
                            key={tier.name}
                            className={`relative flex flex-col justify-between shadow-md   bg-white dark:bg-jacarta-700  text-white overflow-hidden transition-transform duration-300 ease-in-out px-6 py-8 rounded-lg 
                        `}
                          >
                            {tier.isPopular && (
                              <div className="absolute top-0 right-0 bg-purplish/20 dark:bg-white text-sm font-bold px-3 py-1 rounded-bl-lg ">
                                <span className="bg-gradient-to-tr from-hotPink via-purplish to-accent  bg-clip-text text-transparent">
                                  POPULAR
                                </span>
                              </div>
                            )}
                            <div>
                              <div className="flex items-start gap-4 mb-6">
                                <div
                                  className={` rounded-full flex items-center justify-center ${
                                    tier.isPopular
                                      ? "bg-gradient-to-br from-blue-400 to-blue-600"
                                      : "bg-gray-700"
                                  }`}
                                >
                                  <div className="shadow-lg w-[40px] h-[60px] bg-gray-300 dark:bg-none p-2 rounded-lg">
                                    <Image
                                      src={tier.image}
                                      width={40}
                                      height={60}
                                      className=" w-[40px] h-[40px]"
                                    />
                                  </div>
                                </div>
                                <div>
                                  <h3 className="text-2xl font-bold mb-2">
                                    {tier.name}
                                  </h3>
                                  <p className="text-sm text-gray-400 h-10">
                                    {tier.description}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="mb-4">
                              <div className="mb-4">
                                <div className="my-4">
                                  <p className="text-4xl font-bold mb-1">
                                    {tier.packageId === null
                                      ? tier.price
                                      : "$" +
                                        calculatePrice(tier.price).toFixed(2)}
                                  </p>
                                  <p className="text-sm text-gray-400">
                                    Monthly
                                  </p>
                                </div>
                              </div>
                              <div className="border-t border-gray-300 dark:border-gray-200 pt-4 space-y-2">
                                <p className="text-sm font-medium text-gray-300">
                                  {tier.credits.toLocaleString()} wallets
                                </p>
                                <p className="text-xs text-gray-400">
                                  {tier.creditValue} mints max
                                </p>
                              </div>
                            </div>
                            <div>
                              <button
                                disabled={checkoutLoading}
                                onClick={home || PricingPage ? undefined : () => handlePurchaseClick(tier)}
                                className="w-full h-14 btn-gradient font-bold text-lg p-4 rounded-lg transition-all duration-500 hover:-translate-y-1"
                              >
                                {checkoutLoading && checkout === tier.packageId ? (
                                  <div className="relative">
                                    <Loading />
                                  </div>
                                ) : tier.packageId === null ? (
                                  "Contact Us"
                                ) : home || PricingPage ? (
                                  "Coming Soon"
                                ) : (
                                  "Buy Now"
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </Fade>
            </div>
          </div>
        </section>
        <style jsx global>{`


.swiper-pagination {
  position: relative;
  margin-top: 20px;
}

:root[class~="dark"] .swiper-pagination-bullet {
  background: #02f6d1 !important;
}

:root:not([class~="dark"]) .swiper-pagination-bullet {
  background: #131740 !important;
}

.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  opacity: 0.3;
  transition: all 0.3s ease;
}

.swiper-pagination-bullet-active {
  opacity: 1;
  width: 20px;
  border-radius: 4px;
}

:root[class~="dark"] .swiper-button-prev,
:root[class~="dark"] .swiper-button-next {
  color: #02f6d1 !important;
}

:root:not([class~="dark"]) .swiper-button-prev,
:root:not([class~="dark"]) .swiper-button-next {
  color: #131740 !important;
}

.swiper-button-prev,
.swiper-button-next {
  width: 50px !important;
  height: 50px !important;
  background: rgba(2, 246, 209, 0.05);
  border-radius: 50% !important;
  position: absolute;
  top: 50% !important;
  transform: translateY(-50%);
}

.swiper-button-prev {
  left: -25px !important;
}

.swiper-button-next {
  right: -25px !important;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  background: rgba(2, 246, 209, 0.1);
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 1.25rem !important;
  font-weight: bold;
}


.swiper-slide-active .h-[320px] {
  outline-offset: 0px;
  transform: scale(1.02);
  color: #fff !important;
}

.swiper-slide {
  transition: all 0.3s ease;
}

.swiper-slide-active .active-dash-slide {
  background: linear-gradient(to top right, #FF69B4, #804AE4, #02f6d1);
  border: none !important;
  outline: none !important;
}

//  .swiper-slide-active p, .swiper-slide-active h3, .swiper-slide-active svg, .swiper-slide-active span {
//   color: #fff !important;
// }

// .swiper-slide-active .border.border-gray-300 {
//   border: none;
// }
 
`}</style>
        {checkout && (
          <StripeCheckout
            checkout={checkout}
            setCheckout={setCheckout}
            setCheckoutLoading={setCheckoutLoading}
          />
        )}
      </div>
    </>
  );
}

export default Pricing;
