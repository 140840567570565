import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';



export const useAuthRedirect = () => {
  const router = useRouter();
  const [isRedirecting, setIsRedirecting] = useState(false);

  const getRedirectPath = useCallback(() => {

    if (router.isReady && typeof router.query.redirect === 'string') {
      return router.query.redirect;
    }
    return '/';
  }, [router.isReady, router.query.redirect]);

  const redirectToSignIn = useCallback(() => {
    const currentPath = router.asPath;
    router.push(`/signin?redirect=${encodeURIComponent(currentPath)}`);
  }, [router.asPath]);

  const redirectAfterAuth = useCallback(async () => {
    setIsRedirecting(true);
    const redirectPath = getRedirectPath();

// console.log('redirectPath', redirectPath);
    try {
      setTimeout(() => {
     router.push(redirectPath);
        
      }, 500);
    } catch (error) {
      console.error('Redirect error:', error);
      setIsRedirecting(false);
    }
  }, [getRedirectPath, router]);
 
  return {
    redirectToSignIn,
    redirectAfterAuth,
    getRedirectPath,
    isRedirecting,
  };
}; 